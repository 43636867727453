<template>
  <div class="group-template-th">
    <div class="box-setting-header">
      <div class="title loaderPosition">
        <div class="widthTitle">Gestion des groupes</div>
        <div v-if="loadingForFetch" class=" loading ">
          <div class="spinner-border loadingFetch" role="status"></div>
        </div>
      </div>

      <div>
        <b-button
          v-if="checkPermission('GCATTHAC')"
          variant="success"
          class="config-btn ml-2"
          v-b-modal.addGroupe
        >
          <font-awesome-icon icon="plus" /> Ajouter un groupe
        </b-button>
        <b-modal
          id="addGroupe"
          ref="addGroupe"
          :hide-footer="true"
          :hide-header="true"
          no-close-on-backdrop
          @hidden="resetModal"
          modal-class="cutsom-modal-bootstrap "
        >
          <div class="hader mb-2">
            <div class="titleSetting">Ajouter un groupe</div>
            <div class="iconClose" @click.prevent="hideModal('addGroupe')">
              <font-awesome-icon icon="times" />
            </div>
          </div>
          <Card>
            <template v-slot:body>
              <form
                @submit.prevent="handleSubmitGroupe"
                class="form-modal-custom-style"
              >
                <b-form-group
                  label="Nom de groupe"
                  label-for="nom"
                  class="input-modal-champ "
                >
                  <b-form-input
                    autocomplete="off"
                    id="name"
                    v-model="name"
                    required
                  ></b-form-input>
                </b-form-group>
                <div class="actionModel mt-3">
                  <div class="messageError">
                    <div v-if="error" class="error">
                      <ul
                        v-if="Array.isArray(error)"
                        style="list-style-type: none;"
                      >
                        <li v-for="(e, index) in error" :key="index">
                          {{ e }}
                        </li>
                      </ul>
                      <div v-else>{{ error }}</div>
                    </div>
                  </div>
                  <b-button
                    type="submit"
                    class="button-valide-style "
                    :disabled="loading"
                  >
                    <span>
                      Valider
                      <div v-if="loading" class="loading ml-2">
                        <div class="spinner-border" role="status"></div>
                      </div>
                    </span>
                  </b-button>
                </div>
              </form>
            </template>
          </Card>
        </b-modal>
      </div>
    </div>
    <div class="ligne-box-setting-header"></div>
    <div class="body-box-setting">
      <div class="table-rapport-style w-100-p" style="width:100%">
        <b-table
          show-empty
          id="my-table"
          class="custom-table-style table-gestion-categories-style"
          :items="getGroupeTemplate"
          :fields="fields"
          bordered
          sticky-header
          hover
          head-variant="light"
          tbody-tr-class="ligneNormale"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template v-slot:cell(name)="data">
            {{ data.item.name }}
          </template>
          <template v-slot:cell(Actions)="data">
            <b-button
              v-if="checkPermission('GCATTHSC') && data.item.not_deletable == 0"
              class="button-danger-style m-1"
              size="sm"
              variant="danger"
              title="Supprimer"
              @click.prevent.stop="deleteCurrentGroupe(data.item)"
            >
              <font-awesome-icon icon="trash" />
            </b-button>
            <b-button
              v-if="checkPermission('GCATTHMC')"
              class="button-danger-style m-1"
              size="sm"
              variant="success"
              title="Modifier"
              @click.prevent.stop="handleupdateCurrentGroupe(data.item)"
            >
              <font-awesome-icon icon="pencil-alt" />
            </b-button>
            <b-button
              v-if="checkPermission('GTTHTDT')"
              size="sm"
              variant="success"
              title="Table template"
              class="button-danger-style m-1"
              @click.prevent.stop="handleTableTemplateClick(data.item)"
            >
              <font-awesome-icon class icon="list" />
            </b-button>
          </template>
        </b-table>
        <!-- <div class="footer-style mt-2">
          <b-pagination
            v-model="page"
            :total-rows="getTotalRowGroupeTemplate"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div> -->
      </div>
    </div>

    <b-modal
      ref="deleteGroupe"
      id="deleteGroupe"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      modal-class="cutsom-modal-bootstrap "
    >
      <div class="hader mb-2">
        <div class="titleSetting">Supprimer un groupe</div>
        <div class="iconClose" @click.prevent="hideModal('deleteGroupe')">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent="handleDeleteGroupe"
            class="form-modal-custom-style"
          >
            <div class="mt-4 ml-3">
              <p class="text-dark">
                Êtes-vous sur de vouloir supprimer ce groupe?
              </p>
            </div>
            <div class="actionModel">
              <div class="messageError">
                <div v-if="error" class="error">
                  <ul v-if="Array.isArray(error)">
                    <li v-for="(e, index) in error" :key="'error' + index">
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ error }}</div>
                </div>
              </div>
              <b-button
                type="submit"
                class="button-valide-style mt-1"
                :disabled="loading"
              >
                <span>
                  Valider
                  <div v-if="loading" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </form> </template
      ></Card>
    </b-modal>
    <b-modal
      ref="updateGroupeTemplate"
      id="updateGroupeTemplate"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      modal-class="cutsom-modal-bootstrap "
    >
      <div class="hader mb-2">
        <div class="titleSetting">Modifier un groupe</div>
        <div
          class="iconClose"
          @click.prevent="hideModal('updateGroupeTemplate')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent="handleUpdateGroupe"
            class="form-modal-custom-style"
          >
            <b-form-group
              label="Nom de catégorie"
              label-for="nom"
              class="input-modal-champ "
            >
              <b-form-input
                autocomplete="off"
                id="name"
                v-model="name"
                required
              ></b-form-input>
            </b-form-group>
            <div class="actionModel mt-3">
              <div class="messageError">
                <div v-if="error" class="error">
                  <ul
                    v-if="Array.isArray(error)"
                    style="list-style-type: none;"
                  >
                    <li v-for="(e, index) in error" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ error }}</div>
                </div>
              </div>
              <b-button
                type="submit"
                class="button-valide-style "
                :disabled="loading"
              >
                <span>
                  Valider
                  <div v-if="loading" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </form> </template
      ></Card>
    </b-modal>
    <!-- Colonne Template  -->
    <Modal
      v-model="showTableTemplateModal"
      modalClass="ModelClass modal-template-groupe-width"
      title="Table dynamique du template"
      @after-close="resetTableTemplateModal()"
    >
      <form
        v-if="templateToDisplay && templateToDisplay.Columns !== null"
        ref="tableTemplateForm"
        @submit.prevent="submitDynamicTableTemplate"
      >
        <div class="row mb-3">
          <div class="col-2">
            <b-form-group label="Couleur label" class="înput-modal-champ pl-0">
              <v-input-colorpicker
                colorpick-eyedropper-active="false"
                class="color_picker_width "
                v-model="label"
              />
            </b-form-group>
          </div>
          <div class="col-2">
            <b-form-group label="Couleur entête" class="înput-modal-champ pl-0">
              <v-input-colorpicker
                colorpick-eyedropper-active="false"
                class="color_picker_width "
                v-model="color"
              />
            </b-form-group>
          </div>
          <div class="col-3">
            <b-form-group
              label="Différence de largeur"
              class="înput-modal-champ pl-0"
            >
              <b-form-input
                required
                class="input-modal-champ"
                type="number"
                disabled
                v-model="restSum"
              >
              </b-form-input>
            </b-form-group>
          </div>
          <div class="col-3">
            <b-form-group label="Border" class="înput-modal-champ pl-0">
              <b-form-checkbox
                :id="'checkbox-border'"
                name="checkbox-border"
                class="border-checkbox"
                v-model="border"
              >
              </b-form-checkbox>
            </b-form-group>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-1"></div>
          <div class="col-1"></div>
          <div class="col-2">
            <b> Code colonne</b>
          </div>
          <div class="col-3">
            <b> Nom de colonne</b>
          </div>
          <div class="col-5">
            <b> Largeur de colonne % </b>
          </div>
        </div>
        <draggable :list="columns">
          <div class="row" v-for="(column, index) in columns" :key="index">
            <div class="col-1">
              <img src="@/assets/sort.png" class="sort-icon ml-2" />
            </div>
            <p class="m-0" hidden>{{ (column.index = index) }}</p>
            <div class="col-1">
              <b-form-checkbox
                :id="'checkbox-1' + index"
                name="checkbox-1"
                v-model="column.check"
                @change="CalculeRestWidth()"
              >
              </b-form-checkbox>
            </div>
            <div class="col-2 ">
              <b-form-group>{{ column.column }}</b-form-group>
            </div>
            <div class="col-3 ">
              <b-form-group>
                <b-form-input
                  required
                  class="input-modal-champ"
                  v-model="column.column_value"
                >
                </b-form-input>
              </b-form-group>
            </div>
            <div class="col-5">
              <b-form-group>
                <b-form-input
                  required
                  class="input-modal-champ"
                  v-model="column.width"
                  type="number"
                  @change="CalculeRestWidth()"
                >
                </b-form-input>
              </b-form-group>
            </div>
            <hr />
          </div>
        </draggable>
        <center>
          <div class="actionModel  mt-3">
            <div class="messageError">
              <div v-if="getDynamicTableErrorsGroupe" class="error">
                <ul v-if="Array.isArray(getDynamicTableErrorsGroupe)">
                  <li
                    v-for="(e, index) in getDynamicTableErrorsGroupe"
                    :key="index"
                  >
                    {{ e }}
                  </li>
                </ul>
              </div>
              <div v-else class="color-message">{{ sumError }}</div>
            </div>
            <br />
            <b-button
              type="submit"
              class="button-valide-style "
              :disabled="getLodingDynamicTableGroupe"
            >
              <span>
                Valider
                <div v-if="getLodingDynamicTableGroupe" class="loading ml-2">
                  <div class="spinner-border" role="status"></div>
                </div>
              </span>
            </b-button>
          </div>
        </center>
      </form>
    </Modal>
    <!-- End Colonne Template -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VueModal from '@kouts/vue-modal';
import '@kouts/vue-modal/dist/vue-modal.css';
import InputColorPicker from 'vue-native-color-picker';
import draggable from 'vuedraggable';

export default {
  name: 'Gestion-des-catégories',
  data() {
    return {
      name: null,
      error: null,
      loadingForFetch: false,
      loading: false,
      nameToUpdate: null,
      perPage: 10,
      page: 1,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ],
      fields: [
        {
          key: 'name',
          label: 'Nom groupe',
          thStyle: { width: '100%' }
        },
        { key: 'Actions', label: 'Actions' }
      ],
      groupeToDelete: null,
      groupeToUpdate: null,
      showTableTemplateModal: false,
      templateToDisplay: null,
      columns: [],
      restSum: 0,
      sumError: null,
      color: null,
      idGroupeToSend: null,
      label: null,
      border: false
    };
  },
  computed: {
    ...mapGetters([
      'getGroupeTemplate',
      'groupeTemplateError',
      'getGroupeTemplateDetailsLoading',
      'getTotalRowGroupeTemplate',
      'checkPermission',
      'getDynamicTableErrorsGroupe',
      'getLodingDynamicTableGroupe'
    ])
  },
  methods: {
    ...mapActions([
      'fetchAllGroupeTemplate',
      'addNewGroupeTemplate',
      'deleteGroupeTemplate',
      'updateGroupeTemplate',
      'updateTableProductsGroupe'
    ]),
    resetModal() {
      this.name = null;
      this.error = null;
      this.loading = false;
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    async changePerPage() {
      this.loadingForFetch = true;
      this.page = 1;
      this.handleFilter();

      this.loadingForFetch = false;
    },
    async pagination(paginate) {
      this.loadingForFetch = true;
      this.page = paginate;
      this.handleFilter();
      this.loadingForFetch = false;
    },
    async handleFilter() {
      await this.fetchAllGroupeTemplate();
    },
    async handleSubmitGroupe() {
      this.loading = true;
      const response = await this.addNewGroupeTemplate({
        name: this.name
      });
      if (response) {
        this.loading = false;
        this.hideModal('addGroupe');
      } else {
        this.error = this.groupeTemplateError;
        this.loading = false;
      }
    },
    deleteCurrentGroupe(data) {
      this.groupeToDelete = data;
      this.$refs['deleteGroupe'].show();
    },

    async handleDeleteGroupe() {
      this.loading = true;
      const response = await this.deleteGroupeTemplate(this.groupeToDelete);
      if (response) {
        this.loading = false;
        this.hideModal('deleteGroupe');
        // if (this.getGroupeTemplate.length === 0 && this.page > 1) {
        //   let newPage = this.page - 1;
        //   this.pagination(newPage);
        // }
      } else {
        this.loading = false;
      }
    },
    handleupdateCurrentGroupe(data) {
      this.name = data.name;
      this.groupeToUpdate = data;
      this.$refs['updateGroupeTemplate'].show();
    },
    async handleUpdateGroupe() {
      this.loading = true;
      const response = await this.updateGroupeTemplate({
        groupe: this.groupeToUpdate,
        name: this.name
      });
      if (response) {
        this.loading = false;
        this.hideModal('updateGroupeTemplate');
      } else {
        this.loading = false;
        this.error = this.groupeTemplateError;
      }
    },
    handleTableTemplateClick(groups) {
      this.showTableTemplateModal = true;
      let newArrayToDisplay = [];
      newArrayToDisplay = groups?.columns?.map(item => {
        return { ...item, check: false };
      });
      this.templateToDisplay = newArrayToDisplay;
      this.templateToDisplay?.map(item => {
        this.columns.push({
          id: item.id,
          column: item.column,
          column_value: item.column_value,
          width: item.width,
          index: item.index,
          check: item.blocked === 0 ? true : false
        }),
          (this.color = item.color_bg);
        if (item.border == 1) {
          this.border = true;
        } else {
          this.border = false;
        }
        this.label = item.color;
      });
      this.idGroupeToSend = groups?.id;
      for (let i = 0; i < this?.columns?.length; i++) {
        if (this.columns[i].check === true) {
          this.restSum += parseFloat(groups.columns[i].width);
        }
      }
    },
    async submitDynamicTableTemplate() {
      let bodyFormData = new FormData();
      if (this.border == true) {
        this.border = 1;
      } else {
        this.border = 0;
      }
      let sum = 0;
      for (let i = 0; i < this.columns.length; i++) {
        if (this.columns[i].check === true) {
          sum += parseFloat(this.columns[i].width);
        }
      }
      if (sum !== 100) {
        this.sumError = 'La somme des largeurs être égale a 100';
      } else {
        bodyFormData.append('group_id', this.idGroupeToSend);
        bodyFormData.append('color_bg', this.color);
        bodyFormData.append('border', this.border);
        bodyFormData.append('color', this.label);
        for (let i = 0; i < this.columns.length; i++) {
          bodyFormData.append(
            'columns[' + i + '][column]',
            this.columns[i].column
          );
          bodyFormData.append(
            'columns[' + i + '][column_value]',
            this.columns[i].column_value
          );
          bodyFormData.append('columns[' + i + '][id]', this.columns[i].id);
          bodyFormData.append(
            'columns[' + i + '][index]',
            this.columns[i].index
          );
          bodyFormData.append(
            'columns[' + i + '][width]',
            this.columns[i].width
          );
          bodyFormData.append(
            'columns[' + i + '][blocked]',
            this.columns[i].check === true ? 0 : 1
          );
        }
        const response = await this.updateTableProductsGroupe(bodyFormData);
        if (response) {
          this.showTableTemplateModal = false;
          await this.handleFilter();
          this.getAllTemplate.map(item1 =>
            item1.templates.map(item2 => {
              if (item2.id === this.idGroupeToSend) {
                this.selectedTemplate.templates.forEach(element => {
                  if (element.id === this.idGroupeToSend) {
                    element.columns = item2.columns;
                    element.color = item2.columns[0].color_bg;
                    element.label = item2.columns[0].color;
                  }
                });
              }
            })
          );
        }
      }
    },
    resetTableTemplateModal() {
      this.columns = [];
      this.sumError = null;
      this.restSum = null;
    },
    CalculeRestWidth() {
      let sum = 0;
      for (let i = 0; i < this.columns.length; i++) {
        if (this.columns[i].check === true) {
          sum += parseFloat(this.columns[i].width);
        }
      }
      this.restSum = sum;
    }
  },
  components: {
    Card: () => import('@/views/component/card'),
    Modal: VueModal,
    'v-input-colorpicker': InputColorPicker,
    draggable
  },
  async mounted() {
    this.loadingForFetch = true;
    this.handleFilter();
    this.loadingForFetch = false;
  }
};
</script>

<style lang="scss" scoped>
.group-template-th {
  height: 100%;
  .tabs-categorie {
    width: 25%;
    margin: 5px 15px;
    .custom-list-group {
      .list-group-item {
        justify-content: space-between;
        color: #515356;
        display: block;
        font-weight: 600;
        .list-item {
          justify-content: space-between;
        }
      }
    }
    .title {
      color: #515356;
      display: block;
      font-weight: 600;
      margin: 0px 0px 10px 0px;
    }
    .categorie-labels {
      display: flex;
      justify-content: space-between;
    }
    .trash-style {
      margin-left: 5px;
      color: #dc3545;
    }
  }

  .categorie-body {
    display: flex;
    margin: 5px 15px;
    height: calc(100vh - 86px);
    .categorie-details {
      width: 75%;
      .title {
        color: #515356;
        display: block;
        font-weight: 600;
        margin: 5px;
      }
      .input-modal-champ {
        input {
          border-radius: 5px;
        }
      }
      .custom-table-style {
        padding: 5px;
        max-height: calc(100vh - 198px);
        height: calc(100% - 198px);
        width: 100%;
      }
    }
  }
  .ligne-box-setting-header {
    margin-top: 1%;
  }
  .table-gestion-categories-style {
    max-height: calc(100vh - 200px) !important;
    height: calc(100vh - 200px) !important;
    margin-bottom: 0px;
    max-width: 100%;
  }
  .loadingFetch {
    width: 16px;
    height: 16px;
    font-size: 10px;
  }
  .widthTitle {
    width: 180px;
  }
  .loaderPosition {
    display: flex;
    flex-direction: row;
  }
}
.ModelClass {
  border-radius: 5px;
  max-width: 800px;
  width: auto;
  .vm-title {
    font-size: 1.25rem;
  }
  .sort-icon {
    height: 20px;
    width: 20px;
  }
}
.button-table-style {
  background-color: #17a2b8;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  color: #ffffff;
  border: none;
}
</style>
<style>
.modal-template-groupe-width {
  max-width: 75%;
}
</style>
